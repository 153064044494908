import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Message,MessageService} from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { HttpErrorMessageService } from './services/http-error-message.service';

@Component({
  selector: 'app-fallback',
  template: `<p class="alert alert-warning">Wastępił problem w działaniu programu:</p><br/>
             <p style="color: red;">{{errorMessage}}</p>
 `,
  providers: [MessageService]
})
export class FallbackComponent implements OnInit {

  errorMessage:string='';
  
  constructor(        
    private httpErrorMessageService: HttpErrorMessageService
    ) { 
      

    }

    ngOnInit(): void { 
      this.httpErrorMessageService.getErrorMessage().subscribe(error=>{
        this.errorMessage=error;        
      });       
    }
}
