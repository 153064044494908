import { Component, OnInit  } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs/internal/Observable';
import { AuthService } from 'src/app/core/auth.service';
import { ParametryService } from 'src/app/services/parametry.service';
import { WazenieService } from 'src/app/services/wazenie.service';

@Component({
  selector: 'app-menubar',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  items: MenuItem[];
  
  isAuthenticated$: Observable<boolean>;
  isDoneLoading$: Observable<boolean>;
  canActivateProtectedRoutes$: Observable<boolean>;

  isAuthenticated: boolean;
  currentUser: string =  '';
  currentPszok: string = '';
  currentDzien: Date = new Date();

  pl: any;

  constructor(
    private authService: AuthService,
    private parametryService: ParametryService,
    private config: PrimeNGConfig, private translateService: TranslateService,
    private wazenieService: WazenieService
  ) {
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isDoneLoading$ = this.authService.isDoneLoading$;
    this.canActivateProtectedRoutes$ = this.authService.canActivateProtectedRoutes$;
    this.parametryService.filtrPszok.subscribe(pszok=>{
      this.currentPszok=pszok;
    });
    
  }



  login() { this.authService.login(); }
  logout() { this.authService.logout(); }
  refresh() { this.authService.refresh(); }
  reload() { window.location.reload(); }
  clearStorage() { localStorage.clear(); }

  logoutExternally() {
    window.open(this.authService.logoutUrl);
  }

  updateFilterPszok(pszok:string){
    this.parametryService.updateFilterPszok(pszok);
  }
  updateFilterDzien(selectedDzien:Date){
    console.log(selectedDzien);    
    this.parametryService.updateFilterDzien(selectedDzien);
  }

  get hasValidToken() { return this.authService.hasValidToken(); }
  get accessToken() { return this.authService.accessToken; }
  get refreshToken() { return this.authService.refreshToken; }
  get identityClaims() { return this.authService.identityClaims; }
  get idToken() { return this.authService.idToken; }

  get preferred_username() { return this.authService.identityClaims["preferred_username"]; }


  ngOnInit(): void {
    
    // this.translateService.addLangs(['en', 'pl']);
    // this.translateService.setDefaultLang('pl');

    // this.translate('pl');

    this.authService.isAuthenticated$.subscribe(status=>{
      this.isAuthenticated = status;
      if(status == true)
        this.currentUser=this.authService.identityClaims["preferred_username"]; 
      }
    );
  
    this.items = [
      {
          label: 'PSZOK',
          disabled: this.isAuthenticated == false,
          items: [
              {
                  label: 'PSZOK 1', 
                  icon: 'pi pi-fw pi-arrow-up-right',
                  command: (event) => {
                    this.updateFilterPszok('PSZOK1');    
                  }
              },
              {
                label: 'PSZOK 2', 
                icon: 'pi pi-fw pi-arrow-up-right',
                command: (event) => {
                  this.updateFilterPszok('PSZOK2');    
                }    
              },
             
          ]
      },
      
      {
        label: 'Zakończone',
        icon: 'pi pi-fw pi-chevron-circle-left', 
        routerLink: ['/wazenia-zakonczone'],
        disabled: this.isAuthenticated == false        
      },
      {
        label: 'Rozpoczęte',
        icon: 'pi pi-fw pi-chevron-circle-right',         
        routerLink: ['/wazenia-rozpoczete'],
        disabled: this.isAuthenticated == false
      },
      {
        label: 'Nowe',
        icon: 'pi pi-fw pi-plus',
        routerLink: ['/wazenie'],
        disabled: this.isAuthenticated == false,
        command: (event) => {
          this.wazenieService.resetForm$.next()
        }       
    }
  ];
}
// translate(lang: string) {
//   this.translateService.use(lang);
//   this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
// }

}
