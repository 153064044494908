import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { AppMenuComponent } from './app-menu.component';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FallbackComponent } from './fallback.component';
import { ShouldLoginComponent } from './should-login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GminaMenuComponent } from './components/gmina-menu/gmina-menu.component';
import { PszokMenuComponent } from './components/pszok-menu/pszok-menu.component';
import { WazeniaZakonczoneComponent } from './components/wazenia-zakonczone/wazenia-zakonczone.component';
import { WazeniaRozpoczeteComponent } from './components/wazenia-rozpoczete/wazenia-rozpoczete.component';
import { WazenieComponent } from './components/wazenie/wazenie.component';


// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';


import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {MultiSelectModule} from 'primeng/multiselect';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DropdownModule} from 'primeng/dropdown';
import {ProgressBarModule} from 'primeng/progressbar';
import {InputTextModule} from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MenuComponent } from './components/menu/menu.component';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {FieldsetModule} from 'primeng/fieldset';
import {ListboxModule} from 'primeng/listbox';
import {InputNumberModule} from 'primeng/inputnumber';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// const routes: Routes = [
//   {path: 'wazenia/:komorkaOrganizacyjnaSymbol/:filtrDzien', component: WazeniaZakonczoneComponent},
//   {path: 'rozpoczete/:komorkaOrganizacyjnaSymbol/:filtrDzien', component: WazeniaRozpoczeteComponent},
//   {path: 'wazenia/:komorkaOrganizacyjnaSymbol', component: WazeniaZakonczoneComponent},
//   {path: 'przyjecie/:komorkaOrganizacyjnaSymbol', component: WazenieComponent},
//   {path: 'przyjecie/:komorkaOrganizacyjnaSymbol/:id', component: WazenieComponent},
//   {path: 'ustawienia', component: PszokMenuComponent},
//   {path: '', redirectTo: '/wazenia/PSZOK1', pathMatch: 'full'},
//   {path: '**', redirectTo: '/wazenia/PSZOK1', pathMatch: 'full'}
// ];

@NgModule({
  declarations: [
    AppComponent,
    AppMenuComponent,
    FallbackComponent,
    ShouldLoginComponent,
    GminaMenuComponent,
    PszokMenuComponent,
    WazeniaZakonczoneComponent,
    WazeniaRozpoczeteComponent,
    WazenieComponent,
    MenuComponent,    
  ],
  imports: [
    BrowserModule,
    MenubarModule,
    InputTextModule,
    ButtonModule,
    SelectButtonModule,
    FieldsetModule,
    CalendarModule,    
    TableModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,		
		ToastModule,    
    BrowserAnimationsModule,
    MessagesModule,
    MessageModule,
    FieldsetModule,
    ListboxModule,
    InputNumberModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    CoreModule.forRoot(),
    RouterModule.forRoot([
    // { path: '', redirectTo: 'basics/home', pathMatch: 'full' },
    {path: 'wazenia-zakonczone', component: WazeniaZakonczoneComponent},
    {path: 'wazenia-rozpoczete', component: WazeniaRozpoczeteComponent},
    {path: 'wazenie', component: WazenieComponent},
    {path: 'wazenie/:id', component: WazenieComponent},
    {path: 'wazenia/:komorkaOrganizacyjnaSymbol/:filtrDzien', component: WazeniaZakonczoneComponent},
    {path: 'rozpoczete/:komorkaOrganizacyjnaSymbol/:filtrDzien', component: WazeniaRozpoczeteComponent},
    {path: 'wazenia/:komorkaOrganizacyjnaSymbol', component: WazeniaZakonczoneComponent},
    {path: 'przyjecie/:komorkaOrganizacyjnaSymbol', component: WazenieComponent},
    {path: 'przyjecie/:komorkaOrganizacyjnaSymbol/:id', component: WazenieComponent},
    {path: 'ustawienia', component: PszokMenuComponent},

    // Note: this way of module loading requires this in your tsconfig.json: "module": "esnext"
    // { path: 'basics', loadChildren: () => import('./feature-basics/basics.module').then(m => m.BasicsModule) },
    // { path: 'extras', loadChildren: () => import('./feature-extras/extras.module').then(m => m.ExtrasModule) },
    // { path: 'should-login', component: ShouldLoginComponent },
    // { path: '**', component: FallbackComponent },
    {path: 'fallback/:errorMessage', component: FallbackComponent },
    {path: 'fallback', component: FallbackComponent },
    {path: '', redirectTo: '/wazenia-zakonczone', pathMatch: 'full'},
    {path: '**', redirectTo: '/wazenia-zakonczone', pathMatch: 'full'}
], {
    scrollPositionRestoration: 'top' })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
