import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { Gmina } from "../common/gmina";
import { KomorkaOrganizacyjna } from "../common/komorka-organizacyjna";
import { OdpadySelektywnieZebrane } from "../common/odpady-selektywnie-zebrane";
import { Przyjecie } from "../common/przyjecie";
import { RodzajSelekcji } from "../common/rodzaj-selekcji";
import { environment } from "src/environments/environment";
import { SumaWazen } from "../common/suma-wazen";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WazenieService {
  // private baseUrl = 'http://localhost:8080/api/przyjecia';
  private baseUrl = environment.pszokApi + "/przyjecia";
  private rejestrujWazenieUrl = `${this.baseUrl}/rejestruj-wazenie`;
  private edytujWazenieUrl = `${this.baseUrl}/edytuj-wazenie`;
  private lastUpdateTime = `${this.baseUrl}/lastUpdateTime`;
  resetForm$ = new Subject<void>();

  constructor(private httpClient: HttpClient) {}

  getLastUpdateTime(): Observable<Date> {
    return this.httpClient.get<Date>(this.lastUpdateTime);
  }

  getWazenieById(id: number): Observable<OdpadySelektywnieZebrane> {
    // need to build URL based on category id, page and size
    const searchUrl = `${this.baseUrl}/edytuj?id=${id}`;

    console.log("getWazenieById " + searchUrl);

    return this.httpClient.get<OdpadySelektywnieZebrane>(searchUrl);
  }

  getWazenieListPaginate(
    thePage: number,
    thePageSize: number,
    theKomorkaOrganizacyjnaSymbol: string,
    theData: string
  ): Observable<GetResponseOdpadySelektywnieZebrane> {
    // need to build URL based on category id, page and size
    const searchUrl =
      `${this.baseUrl}/lista?komorkaOrganizacyjnaSymbol=${theKomorkaOrganizacyjnaSymbol}` +
      `&data=${theData}` +
      `&page=${thePage}&size=${thePageSize}`;

    // console.log("getWazenieListPaginate " + searchUrl);

    return this.httpClient.get<GetResponseOdpadySelektywnieZebrane>(searchUrl);
  }

  getZakonczoneListPaginate(
    thePage: number,
    thePageSize: number,
    theKomorkaOrganizacyjnaSymbol: string,
    theData: string
  ): Observable<GetResponseOdpadySelektywnieZebrane> {
    // need to build URL based on category id, page and size
    const searchUrl =
      `${this.baseUrl}/zakonczone?komorkaOrganizacyjnaSymbol=${theKomorkaOrganizacyjnaSymbol}` +
      `&data=${theData}` +
      `&page=${thePage}&size=${thePageSize}`;

    //  console.log("getWazenieListPaginate " + searchUrl);

    return this.httpClient.get<GetResponseOdpadySelektywnieZebrane>(searchUrl);
  }

  getRozpoczeteListPaginate(
    thePage: number,
    thePageSize: number,
    theKomorkaOrganizacyjnaSymbol: string,
    theData: string
  ): Observable<GetResponseOdpadySelektywnieZebrane> {
    const searchUrl =
      `${this.baseUrl}/rozpoczete?komorkaOrganizacyjnaSymbol=${theKomorkaOrganizacyjnaSymbol}` +
      `&data=${theData}` +
      `&page=${thePage}&size=${thePageSize}`;

    // console.log("getWazenieListPaginate " + searchUrl);

    return this.httpClient.get<GetResponseOdpadySelektywnieZebrane>(searchUrl);
  }

  getSumaWazenList(
    nazwaGminy: string,
    miejscowosc: string,
    ulica: string,
    nrDomu: string,
    odDnia: string,
    doDnia: string
  ): Observable<SumaWazen[]> {
    const searchUrl =
      `${this.baseUrl}/suma-wazen-sztuk?nazwaGminy=${nazwaGminy}` +
      `&miejscowosc=${miejscowosc}` +
      `&ulica=${ulica}` +
      `&nrDomu=${nrDomu}` +
      `&odDnia=${odDnia}` +
      `&doDnia=${doDnia}`;
    console.log("getSumaWazenList " + searchUrl);
    return this.httpClient.get<SumaWazen[]>(searchUrl);
  }

  dodajWazenie(wazenie: Przyjecie): Observable<any> {
    console.log(wazenie);
    return this.httpClient.post<Przyjecie>(this.rejestrujWazenieUrl, wazenie);
  }
  edytujWazenie(wazenie: Przyjecie): Observable<any> {
    const url = this.edytujWazenieUrl + "/" + wazenie.id;
    console.log(url);
    return this.httpClient.put<Przyjecie>(url, wazenie);
  }
}

interface GetResponseOdpadySelektywnieZebrane {
  content: {
    odpadySelektywnieZebrane: OdpadySelektywnieZebrane[];
  };
  pageable: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  };
}
