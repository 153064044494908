<form [formGroup]="przyjecieFormGroup" (ngSubmit)="onSubmit($event)">
    <p-fieldset legend="Deklaracje">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <div formGroupName="szukaj" class="form-area">
                <div>                    
                    <div class="p-field p-fluid">
                        <label for="szukajMiejscowosc" class="block text-900 font-medium mb-2">Miejscowość</label>
                        <input id="szukajMiejscowosc" pInputText class="p-inputtext-md" placeholder="Uzupełnij miejscowość"
                            formControlName="szukajMiejscowosc" type="text" (input)="handleSearchAdres()" class="w-full mb-3" />
                    </div>
                    <div class="p-field p-fluid">
                        <label for="szukajUlica" class="block text-900 font-medium mb-2">Ulica</label>
                        <input id="szukajUlica" pInputText class="p-inputtext-md" placeholder="Wskaż ulicę"
                            formControlName="szukajUlica" type="text" (input)="handleSearchAdres()" class="w-full mb-3" />
                    </div>
                    <div class="p-field p-fluid">
                        <label for="szukajNrDomu" class="block text-900 font-medium mb-2">Nr domu</label>
                        <input id="szukajNrDomu" pInputText class="p-inputtext-sm" placeholder="Podaj numer domu"
                            formControlName="szukajNrDomu" type="text" (input)="handleSearchAdres()" style="margin-bottom: 3px;"/>
                    </div>
                </div>
                <div>                   
                    <span *ngFor="let s of listaAdresow">
                        <button type="button" pButton name="complete" (click)="completeAdres(s)"
                            class="btn btn-primary btn-sm" style="margin-right: 3px; margin-bottom: 3px;">
                            {{ s.miejscowosc }} {{ s.ulica }} {{ s.nrDomu }}
                        </button>
                    </span>                   
                </div>
            </div>
        </div>
    </p-fieldset>
    <p-fieldset legend="Mieszkaniec">
        <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <!-- mieszkaniec -->        
            <div formGroupName="mieszkaniec" class="form-area">              
                <div>
                    <div class="p-field p-fluid">
                        <label for="gmina" class="block text-900 font-medium mb-2">Gmina</label>
                        <p-dropdown id="gmina" [options]="gminy" formControlName="gmina" optionValue="nazwaGminy" optionLabel="nazwaGminy"></p-dropdown>
                        <p-message severity="error" text="Wskaż gminę" *ngIf="gmina.invalid && (gmina.dirty || gmina.touched)"></p-message>
                    </div>
                    

                    <div class="p-field p-fluid">
                        <label for="miejscowosc" class="block text-900 font-medium mb-2">Miejscowość</label>                
                        <input id="miejscowosc" pInputText class="p-inputtext-sm" placeholder="Miejscowość" formControlName="miejscowosc"
                                    type="text" (change)="onChangeMieszkaniec()" />
                        <p-message severity="error" text="Uzupełnij miejscowość" *ngIf="miejscowosc.invalid && (miejscowosc.dirty || miejscowosc.touched)"></p-message>
                    </div>
                    
                    <div class="p-field p-fluid">
                        <label for="ulica" class="block text-900 font-medium mb-2">Ulica</label>                
                        <input id="ulica" pInputText class="p-inputtext-sm" placeholder="Ulica" formControlName="ulica"
                                    type="text" (change)="onChangeMieszkaniec()" />
                        <p-message severity="error" text="Uzupełnij ulicę" *ngIf="ulica.invalid && (ulica.dirty || ulica.touched)"></p-message>
                    </div>

                    <div class="p-field p-fluid">
                        <label for="nrDomu" class="block text-900 font-medium mb-2">Nr domu</label>                
                        <input id="nrDomu" #nrDomu pInputText class="p-inputtext-sm" placeholder="nr domu" formControlName="nrDomu"
                                    type="text" (change)="onChangeMieszkaniec()" />
                        <p-message severity="error" text="Uzupełnij nr domu" *ngIf="nrDomu.invalid && (nrDomu.dirty || nrDomu.touched)"></p-message>
                    </div>
                    <div class="p-field p-fluid" *ngIf="wlasciciel.value">                        
                        <p-message severity="info" text="Właściciel wg. deklaracji:"></p-message>               
                        <p-message severity="warn" text="{{wlasciciel.value}}" ></p-message>                                       
                    </div>
                    <div class="p-field p-fluid" *ngIf="czyFirma.value">                        
                        <p-message severity="info" text="Mieszkaniec"  *ngIf="czyFirma.value === false"></p-message>  
                        <p-message severity="error" text="Uwaga firma!" *ngIf="czyFirma.value === true"></p-message>                                  
                    </div>
                    
                    <div class="p-field p-fluid">                
                        <label class="block text-900 font-medium mb-2">Zestawienie ważeń w bieżącym roku kalendarzowym</label>                    
                        <ul>
                            <li *ngFor="let poz of listaSumaWazen">
                                {{ poz.rodzajSelekcji }} - {{ poz.sumaWagi }} kg
                                <ng-container *ngIf="poz.sumaSztuk !== null">
                                 / {{ poz.sumaSztuk }} szt. 
                                </ng-container>
                                ({{
                                poz.liczbaWazen
                                }}
                                zapisów)
                            </li>
                        </ul>                    
                    </div>
                </div>
            </div>
        </div>
            
    </p-fieldset>

    <p-fieldset legend="Notatka">
        <!-- Uwagi -->
        <div formGroupName="notatka" class="form-area">
            <div>
                <div class="p-field p-fluid">
                    <label for="uwagi">Uwagi</label>
                    <input id="uwagi" pInputText class="p-inputtext-sm" placeholder="Uwagi" formControlName="uwagi" />                    
                </div>
                <div class="p-field p-fluid">
                    <label for="deklaracja">Deklaracja</label>
                    <input id="deklaracja" pInputText class="p-inputtext-sm" placeholder="Deklaracja" formControlName="deklaracja" disabled="true"/> 
                </div>
                <div class="p-field p-fluid">
                    <label for="id">Rekord</label>
                    <input id="id" pInputText class="p-inputtext-sm" placeholder="Id" formControlName="id"  disabled="true"/> 
                </div>
            </div>
           
        </div>
    </p-fieldset>

    <p-fieldset legend="Selekcja">
            <!-- Selekcja -->
            <div formGroupName="selekcja" class="form-area">
                <div>                    
                    <div class="p-field p-fluid">
                        <label for="rodzajeSelekcji" class="block text-900 font-medium mb-2">Rodzaje selekcji</label>                    
                        <p-listbox id="rodzajeSelekcji" [options]="rodzajeSelekcji" formControlName="rodzajSelekcji" scrollHeight="100%"
                        optionValue="nazwa" optionLabel="nazwa"  (onClick)="onChangeSelekcja()" ></p-listbox>
                    </div>
                    <div class="p-field p-fluid">
                        <label for="data">Data</label>
                        <p-calendar id="data" formControlName="data" dateFormat="yy-mm-dd" inputId="data" ></p-calendar> 
                    </div>
                    <div class="p-field p-fluid">
                        <label for="brutto">Brutto</label>
                        <p-inputNumber allowEmpty="true" inputId="brutto" formControlName="brutto" (onBlur)="obliczNetto()">
                        </p-inputNumber>
                    </div>
                    <div class="p-field p-fluid">
                        <label for="tara">Tara</label>
                        <p-inputNumber allowEmpty="true" inputId="tara" formControlName="tara" (onBlur)="obliczNetto()">
                        </p-inputNumber>
                    </div>
                    <div class="p-field p-fluid">
                        <label for="netto">Netto</label>
                        <p-inputNumber allowEmpty="true" inputId="netto" formControlName="netto" (onBlur)="onUpdateNetto()">
                        </p-inputNumber>
                    </div>
                    <div class="p-field p-fluid">
                        <label for="liczbaSztuk">Ilość (szt) np. ilość opon</label>
                        <p-inputNumber allowEmpty="true" inputId="liczbaSztuk" formControlName="liczbaSztuk" >
                        </p-inputNumber>
                    </div>
                </div>
            </div>
        </p-fieldset>
        <p-fieldset legend="Akcja">
            <div class="p-field" >
                <span *ngIf="this.isBtn1LabelVisible">
                    <button pButton type="submit" name="save_and_close" class="p-button-primary" style="margin-right: 3px;">
                        {{ btn1Label }}
                    </button>
                </span>
                <span *ngIf="this.isBtn2LabelVisible"  >
                    <button pButton type="submit" name="save_and_continue" class="p-button-secondary" style="margin-right: 3px;">
                        {{ btn2Label }}
                    </button>
                </span> 
                <span>
                    <button pButton type="button" (click)="clearForm()" class="p-button-tretary">
                        Pusty formularz
                    </button>
                </span>                
            </div>
            <p-messages></p-messages>
        </p-fieldset>
       

            <!-- submit button-->
            <!-- <div class="text-center">
                <span *ngIf="this.isBtn1LabelVisible">
                    <button type="submit" class="btn btn-info" name="save_and_close">
                        {{ btn1Label }}
                    </button>
                </span>
                <span *ngIf="this.isBtn2LabelVisible">
                    <button type="submit" class="btn btn-info" name="save_and_continue">
                        {{ btn2Label }}
                    </button>
                </span>
                <span *ngIf="false">
                    <button type="submit" class="btn btn-info" name="reset">Wyczyść</button>
                </span>
            </div> -->
            <div class="text-left">
                <div class="alert alert-warning mt-1">
                    <ul>
                        <li>OP - Żużyte opony</li>
                        <li>B - Odpady ulegające biodegradacji</li>
                        <li>MA - Opakowania z papieru i tektury</li>
                        <li>ME - Opakowania z metali</li>
                        <li>G - Odpady wielkogabarytowe</li>
                        <li>O - Niesegregowane (zmieszane) odpady komunalne</li>
                        <li>P - Opakowania z tworzyw sztucznych i metali</li>
                        <li>SB - Szkło białe</li>
                        <li>SM - Opakowania ze szkła oraz z papieru i tektury</li>
                        <li>E - Zuzyty sprzęt elektryczny i elektroniczny</li>
                        <li>S - Opakowania ze szkła</li>
                        <li>D - Inne niewymienione frakcje zbierane w sposób selektywny</li>
                        <li>
                            MB - Opakowania z papieru i tektury oraz odpady ulegające
                            biodegradacji
                        </li>
                        <li>NS - Zmieszane niesegregowane</li>
                        <li>GR - Gruz</li>
                    </ul>
                </div>
            </div>
        
</form>