<div *ngIf="isAuthenticated === false">
    <div style="color: red; font-size: 24px; text-align: center; margin-top: 50px;">Zaloguj się aby przeglądać listę zakończonych ważeń!</div>
</div>   

<div *ngIf="isAuthenticated === true">
    <p-table [value]="wazenia" [lazy]="true" (onLazyLoad)="loadList($event)" 
        [paginator]="true" [rows]="10" [showCurrentPageReport]="true" responsiveLayout="scroll"
        currentPageReportTemplate="Pozycje od {first} do {last} z {totalRecords}" [rowsPerPageOptions]="[10,25,50]"
        [totalRecords]="totalRecords" [loading]="loading">
        <ng-template pTemplate="caption">
            Ważenia zakończone dla: <span style="font-weight: bold;"> {{ currentPszok }} </span>
            <span style="font-weight: bold;"> {{ currentDzien | date:'yyyy-MM-dd':'UTC'  }} </span>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Data</th>
                <th>Adres</th>                            
                <th>Rodzaj selekcji</th> 
                <th>Waga kg</th> 
                <th>Ilość (szt)</th>  
                <th>Operator</th>  
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tempWazenie>
            <tr>
                <td>{{ tempWazenie.data }}</td>
                <td>{{ tempWazenie.gmina['nazwaGminy'] }}
                    {{ tempWazenie.ulica}} {{ tempWazenie.nrDomu }}
                    {{ tempWazenie.miejscowosc}}
                </td>
                <td>
                    <div *ngIf="tempWazenie.rodzajSelekcji !== null">
                        {{ tempWazenie.rodzajSelekcji.nazwa}}
                    </div>
                    <button pButton type="button" label="Click" routerLink="/wazenie/{{ tempWazenie.id }}" label="Edytuj"></button>                
                </td>
                <td style="text-align: right;">{{ tempWazenie.waga }}</td>
                <td style="text-align: right;">{{ tempWazenie.liczbaSztuk }}</td>
                <td>{{ tempWazenie.zmodyfikowal }}</td>            
            </tr>
        </ng-template>
    </p-table>
</div>





