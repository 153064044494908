import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { OdpadySelektywnieZebrane } from 'src/app/common/odpady-selektywnie-zebrane';
import { AuthService } from 'src/app/core/auth.service';
import { ParametryService } from 'src/app/services/parametry.service';
import { WazenieService } from 'src/app/services/wazenie.service';

@Component({
  selector: 'app-wazenia-zakonczone',
  templateUrl: './wazenia-rozpoczete.component.html',
  styleUrls: ['./wazenia-rozpoczete.component.css']
})
export class WazeniaRozpoczeteComponent implements OnInit, OnDestroy {
  
  lastUpdateTime: Date = null; 
  wazenia: OdpadySelektywnieZebrane[] = [];

  currentPszok: string = '';
  currentDzien: string = '';

  //PF
  totalRecords: number;
  loading: boolean;
  cols: any[];

  lastEvent: LazyLoadEvent;


  private intervalId;

  isAuthenticated: boolean = false;

 
  // previousKeyword: string = null;

  constructor(private wazenieService: WazenieService,    
    private parametryService: ParametryService,  
    private route: ActivatedRoute,
    private router: Router,
    private primengConfig: PrimeNGConfig,
    private authService: AuthService) { 

    }

  ngOnInit(): void {

    this.authService.isAuthenticated$.subscribe(status=>{
      this.isAuthenticated = status;     
      }
    );
    
    if(this.isAuthenticated){

      this.parametryService.filtrPszok.subscribe(pszok=>{
        this.currentPszok=pszok;
        this.loadListStart();
      });
      this.parametryService.filtrDzien.subscribe(dzien=>{
        console.log("Zmiana daty: " + this.currentDzien);
        this.currentDzien=dzien;
        this.loadListStart();
      })
      
      this.intervalId = setInterval(()=>{
        this.checkLastUpdateTime();
      },5000);
      this.route.paramMap.subscribe(() => {
      
        this.loadListStart();
      });
    }

    this.loading = false;
    this.primengConfig.ripple = true;
    
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  checkLastUpdateTime(){
      this.wazenieService.getLastUpdateTime()
      .subscribe(czas=>{
        if(this.lastUpdateTime === czas){
          //nic sie nie zmieniło
        }else{
          this.lastUpdateTime = czas;
          this.loadListStart();
        }
        
      });
  }

  loadListStart() {   
    this.loading = true;


  this.wazenieService.getRozpoczeteListPaginate(0,10,
                                             this.currentPszok,
                                             this.currentDzien)
                                             .subscribe(this.processResult());
  }

  loadList(event: LazyLoadEvent) {
    this.lastEvent = event;  
    this.loading = true;
    const page=Math.floor(event.first/event.rows);
    // console.log(page + " " + event.first + " " + event.rows + " " + this.currentPszok + " " + this.currentDzien);
    this.wazenieService.getRozpoczeteListPaginate(page,
                                               event.rows,
                                               this.currentPszok,
                                               this.currentDzien)
                                               .subscribe(this.processResult());
  }

  

  processResult() {
    return data => {
      this.wazenia = data.content;            
      this.totalRecords = data.totalElements;
      this.loading = false;              
    };
  }


  // listWazenia() {
  //   this.handleListWazenia();
  // }
  // handleListWazenia() {
   
  //   const hasKomorkaOrganizacyjnaSymbol: boolean = this.route.snapshot.paramMap.has('komorkaOrganizacyjnaSymbol');
  //   if (hasKomorkaOrganizacyjnaSymbol) {      
  //     this.currentKomorkaOrganizacyjnaSymbol = this.route.snapshot.paramMap.get('komorkaOrganizacyjnaSymbol');
  //   }
  //   else {      
  //     this.currentKomorkaOrganizacyjnaSymbol = 'PSZOK1';
  //   }


  //   const hasFiltrDzien: boolean = this.route.snapshot.paramMap.has('filtrDzien');
  //   if (hasFiltrDzien) {      
  //     this.currentFiltrDzien = this.route.snapshot.paramMap.get('filtrDzien');
  //   }
  //   else {      
  //     this.currentFiltrDzien = new Date().toISOString().substring(0,10);
  //   }

    
  //   if (this.previousKomorkaOrganizacyjnaSymbol != this.currentKomorkaOrganizacyjnaSymbol) {
  //     this.thePageNumber = 1;
  //   }

  //   this.previousKomorkaOrganizacyjnaSymbol = this.currentKomorkaOrganizacyjnaSymbol;


  //   if (this.previousFiltrDzien != this.currentFiltrDzien) {
  //     this.thePageNumber = 1;
  //   }

  //   this.previousFiltrDzien = this.currentFiltrDzien;

  //   // console.log(`currentKomorkaOrganizacyjnaSymbol=${this.currentKomorkaOrganizacyjnaSymbol}, thePageNumber=${this.thePageNumber}`);

  //   // console.log(`currentFiltrDzien=${this.currentFiltrDzien}, thePageNumber=${this.thePageNumber}`);
  //   // now get the products for the given category id
  //   // console.log(`${this.thePageNumber}, ${this.thePageSize}`)
  //   this.wazenieService.getRozpoczeteListPaginate(this.thePageNumber - 1,
  //                                              this.thePageSize,
  //                                              this.currentKomorkaOrganizacyjnaSymbol,
  //                                              this.currentFiltrDzien)
  //                                              .subscribe(this.processResult());
  // }

  // processResult() {
  //   return data => {
  //     this.wazenia = data.content;      
  //     this.thePageNumber = data.number + 1;
  //     this.thePageSize = data.size;
  //     this.theTotalElements = data.totalElements;
  //     // console.log(`${this.thePageNumber} / 
  //     //   ${this.thePageSize} z 
  //     //   ${this.theTotalElements}`);
        
  //   };
  // }

  // updatePageSize(pageSize: number) {
  //   this.thePageSize = pageSize;
  //   this.thePageNumber = 1;
  //   this.listWazenia();
  // }

  // refreshPage() {
  //   window.location.reload();
  //  }
  

}
