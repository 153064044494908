import { Component, OnInit } from '@angular/core';
import { Gmina } from 'src/app/common/gmina';
import { GminaService } from 'src/app/services/gmina.service';

@Component({
  selector: 'app-gmina-menu',
  templateUrl: './gmina-menu.component.html',
  styleUrls: ['./gmina-menu.component.css']
})
export class GminaMenuComponent implements OnInit {

  gminy: Gmina[];

  constructor(private gminaService: GminaService) { }

  ngOnInit(): void {
    this.listGminy();
  }
  listGminy() {
    this.gminaService.getGminy().subscribe(
      data => {
        // console.log('Gminy: ' + JSON.stringify(data));
        this.gminy=data;
      }
    )
  }

}


