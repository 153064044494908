import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { Deklaracja } from '../common/deklaracja';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeklaracjeService {

  // private rejestrDeklaracjiUrl = 'http://localhost:8081/api/rejestr-deklaracji/';
  private rejestrDeklaracjiUrl = environment.greenApi + '/rejestr-deklaracji/';

  constructor(private httpClient: HttpClient) { }

  szukajAdresu(adres:string): Observable<Deklaracja[]> {
    const searchUrl = `${this.rejestrDeklaracjiUrl}szukaj-adresu?adres=${adres}`; 
    // console.log('szukajMiejscowoscUlicaNr: ' + searchUrl) ;
    return this.httpClient.get<Deklaracja[]>(searchUrl)
          .pipe(
            catchError((err) => {
              console.log('error caught in service')
              console.error(err);

              //Handle the error here

              return throwError(() => new Error('szukajAdresu '))    //Rethrow it back to component
            })  
          )
  }

  szukajMiejscowoscUlicaNr(miejscowosc:string, ulica:string, nr:string): Observable<Deklaracja[]> {
    const searchUrl = `${this.rejestrDeklaracjiUrl}szukaj-miejscowosc-ulica-nr?miejscowosc=${miejscowosc}&ulica=${ulica}&nr=${nr}`; 
    // console.log('szukajMiejscowoscUlicaNr: ' + searchUrl) ;
    return this.httpClient.get<Deklaracja[]>(searchUrl);    
  }

  
}


