import { Injectable } from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { HttpErrorMessageService } from '../services/http-error-message.service';



@Injectable({
  providedIn: 'root'
})
export class GlobalHttpInterceptorService implements HttpInterceptor{

  constructor(private router: Router,
    private authService: AuthService, 
    private httpErrorMessageService: HttpErrorMessageService   
    ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    return next.handle(req).pipe(
      catchError((error) => {
        
        if (error instanceof HttpErrorResponse) {          
          if (error.error instanceof ErrorEvent) {
              console.error("Error Event:" + error.message);
          } else {
              // console.log(`error status : ${error.status} ${error.statusText}`);
              switch (error.status) {
                case 0:      //login
                  // console.error("Error 0: " + error.message);       
                  this.httpErrorMessageService.updateErrorMessage(error.message);                                             
                  this.router.navigateByUrl("/fallback");                  
                  break; 
                case 400:      //BadRequest
                  // console.error("Error 0: " + error.message);       
                  this.httpErrorMessageService.updateErrorMessage(error.message);                                             
                  // this.router.navigateByUrl("/fallback");   
                  return;               
                  // break;                
                case 401:      //login
                  // console.error("Error 401 - login");
                  this.authService.login();
                  break;
                case 403:     //forbidden
                  // console.error("Error 403 - forbiden");
                  this.router.navigateByUrl("/unauthorized");                  
                  break;                
              }
          } 
        } else {
            console.error("some thing else happened");
        }
        return throwError(() => new Error(`error status : ${error.status} ${error.statusText}`));
      })
    )
  }
}
