import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
// import { map } from 'rxjs/internal/operators/map';
import { Gmina } from '../common/gmina';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GminaService {
  private findAllGminyUrl = environment.pszokApi + '/slowniki/findAllGminy';
  private findObslugiwaneGminyUrl =  environment.pszokApi + '/slowniki/findObslugiwaneGminy';

  constructor(private httpClient: HttpClient) { }

  getGminy(): Observable<Gmina[]> {

    return this.httpClient.get<Gmina[]>(this.findAllGminyUrl);    
  }

  getObslugiwaneGminy(): Observable<Gmina[]> {    
    return this.httpClient.get<Gmina[]>(this.findObslugiwaneGminyUrl);    
  }

}
