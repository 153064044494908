import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorMessageService {

  errorMessage: Subject<string> = new BehaviorSubject<string>("");

  updateErrorMessage(error:string){   
    this.errorMessage.next(error);
  }

  getErrorMessage(): Observable<string> {
    return this.errorMessage;
  }
 
}
