import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs/internal/Observable';
import { filter } from 'rxjs/operators';
import { OdpadySelektywnieZebrane } from 'src/app/common/odpady-selektywnie-zebrane';
import { AuthService } from 'src/app/core/auth.service';
import { ParametryService } from 'src/app/services/parametry.service';
import { WazenieService } from 'src/app/services/wazenie.service';

@Component({
  selector: 'app-wazenia-zakonczone',
  templateUrl: './wazenia-zakonczone.component.html',
  styleUrls: ['./wazenia-zakonczone.component.css']
})
export class WazeniaZakonczoneComponent implements OnInit, OnDestroy {

  lastUpdateTime: Date = null; 

  wazenia: OdpadySelektywnieZebrane[] = [];
  
  currentPszok: string = '';
  currentDzien: string = '';

  //PF
  totalRecords: number;
  loading: boolean;
  cols: any[];

  lastEvent: LazyLoadEvent;


  private intervalId;

  isAuthenticated: boolean = false;
 

 

  constructor(private wazenieService: WazenieService,  
    private parametryService: ParametryService,  
    private route: ActivatedRoute,
    private router: Router,
    private primengConfig: PrimeNGConfig,
    private authService: AuthService
    ) { }

  ngOnInit(): void {

    this.authService.isAuthenticated$.subscribe(status=>{
      this.isAuthenticated = status;     
      }
    );
    
    if(this.isAuthenticated){
      this.parametryService.filtrPszok.subscribe(pszok=>{
        this.currentPszok=pszok;
        this.loadListStart();
      });
      this.parametryService.filtrDzien.subscribe(dzien=>{
        console.log("Zmiana daty: " + this.currentDzien);
        this.currentDzien=dzien;
        this.loadListStart();
      })
      
      this.intervalId = setInterval(()=>{
        this.checkLastUpdateTime();
      },5000);
      this.route.paramMap.subscribe(() => {
      
        this.loadListStart();
      });
    }

    

    this.loading = false;
    this.primengConfig.ripple = true;
   
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  checkLastUpdateTime(){
    this.wazenieService.getLastUpdateTime()
    .subscribe(czas=>{
      if(this.lastUpdateTime === czas){
        //nic sie nie zmieniło
      }else{
        this.lastUpdateTime = czas;
        this.loadListStart();
      }
      
    });
}

  
  loadListStart() {   
    this.loading = true;


    this.wazenieService.getZakonczoneListPaginate(0,10,
                                             this.currentPszok,
                                             this.currentDzien)
                                             .subscribe(this.processResult());
  }

  loadList(event: LazyLoadEvent) {
    this.lastEvent = event;  
    this.loading = true;
    const page=Math.floor(event.first/event.rows);
    // console.log(page + " " + event.first + " " + event.rows + " " + this.currentPszok + " " + this.currentDzien);
    this.wazenieService.getZakonczoneListPaginate(page,
                                               event.rows,
                                               this.currentPszok,
                                               this.currentDzien)
                                               .subscribe(this.processResult());
  }

  

  processResult() {
    return data => {
      this.wazenia = data.content;            
      this.totalRecords = data.totalElements;
      this.loading = false;              
    };
  }

  

 

  

}
