<p-menubar [model]="items">
    <div>
        <span style="font-weight: bold;"> {{ currentPszok }} </span>
        
        <label for="dateformat">, dzień</label>
        <p-calendar [(ngModel)]="currentDzien"  dateFormat="yy-mm-dd" inputId="dateformat" (onSelect)="updateFilterDzien(currentDzien)"></p-calendar>        
        
        <span *ngIf="isAuthenticated">
            Witaj {{currentUser}}! 
            <button type="button" pButton label="Wyloguj" (click)='logout()' icon="pi pi-power-off" style="margin-left:.25em"></button>
        </span>
        <span *ngIf="isAuthenticated === false">
            <button type="button" pButton label="Zaloguj" (click)='login()' icon="pi pi-user" style="margin-left:.25em"></button>
        </span>     
    </div>
    
</p-menubar>

