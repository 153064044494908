import { NumericLiteral } from "typescript";

export class Przyjecie {
    id: number;
    zamieszkale: boolean;
    brutto: number;
    tara: number;
    waga: number;
    liczbaSztuk: number;    
    data: Date;
    uwagi: string;
    miejscowosc: string;
    ulica: string;
    nrDomu: string;    
    gmina: string;
    rodzajSelekcji: string;
    komorkaOrganizacyjna: string; 
    deklaracja: string;
    wlasciciel: string;
    
    
    zapiszKontynuuj: boolean;

    adres():string{
        console.log("adres");
        if(this.ulica == '-' || this.ulica == '')
            return `${this.miejscowosc} ${this.nrDomu}`;
        else
            return `${this.miejscowosc} ${this.ulica} ${this.nrDomu}`;
    }
}

