<div class="menu-sidebar-content js-scrollbar1">
    <nav class="navbar-sidebar">
        <ul class="list-unstyled navbar-list">

            <li *ngFor="let tempGmina of gminy">

                <a routerLink="/gmina/{{ tempGmina.id }}" routerLinkActive="active-link">
                    {{ tempGmina.nazwaGminy }}
                </a>

            </li>
        
        </ul>
    </nav>
</div>
