import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Gmina } from "src/app/common/gmina";
import { Deklaracja } from "src/app/common/deklaracja";
import { RodzajSelekcjiService } from "src/app/services/rodzaj-selekcji.service";
import { DeklaracjeService } from "src/app/services/deklaracje.service";
import { GminaService } from "src/app/services/gmina.service";
import { RodzajSelekcji } from "src/app/common/rodzaj-selekcji";
import { Przyjecie } from "src/app/common/przyjecie";
import { WazenieService } from "src/app/services/wazenie.service";
import { OdpadySelektywnieZebrane } from "src/app/common/odpady-selektywnie-zebrane";
import { SumaWazen } from "src/app/common/suma-wazen";
import { Message, MessageService } from "primeng/api";
import { PrimeNGConfig, SelectItemGroup } from "primeng/api";
import { FieldsetModule } from "primeng/fieldset";
import { AuthService } from "src/app/core/auth.service";
import { ParametryService } from "src/app/services/parametry.service";
import { catchError, switchMap } from "rxjs/operators";
import { of, Subject, Subscription } from "rxjs";

@Component({
  selector: "app-wazenie",
  templateUrl: "./wazenie.component.html",
  styleUrls: ["./wazenie.component.css"],
  providers: [MessageService],
})
export class WazenieComponent implements OnInit, OnDestroy {
  @ViewChild("nrDomu") nrDomuField: ElementRef;

  przyjecieFormGroup: UntypedFormGroup;

  gminy: Gmina[] = [];
  rodzajeSelekcji: RodzajSelekcji[] = [];
  listaAdresow: Deklaracja[] = [];

  listaSumaWazen: SumaWazen[] = [];

  currentKomorkaOrganizacyjnaSymbol: string = "";
  currentFiltrDzien: string = new Date().toISOString().substring(0, 10);

  odDnia: string; //Do sumowania kg dla adresu
  doDnia: string; //Do sumowania kg dla adresu

  rocordId: number;
  record: OdpadySelektywnieZebrane;

  btn1Label: string = "Zakończ";
  btn2Label: string = "Bufor";
  currentPszok: string = "";

  isAuthenticated: boolean = false;

  private searchSubject = new Subject<{
    miejscowosc: string;
    ulica: string;
    nr: string;
  }>();
  private subscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private gminaService: GminaService,
    private deklaracjeService: DeklaracjeService,
    private rodzajSelekcjiService: RodzajSelekcjiService,
    private wazeniaService: WazenieService,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private parametryService: ParametryService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    //Wyznaczenie dat do sumowania wag - 365dni
    // eslint-disable-next-line prefer-const
    let obliczenieDaty = new Date(); //Dzis
    
    // obliczenieDaty.setDate(obliczenieDaty.getDate() - 365);
    obliczenieDaty =  new Date(obliczenieDaty.getFullYear(), 0, 1); 
    
    
    this.odDnia = obliczenieDaty.getFullYear()+"-01-01";
    this.doDnia = obliczenieDaty.getFullYear()+"-12-31";
    
    console.log(this.odDnia + " " + this.doDnia)

    // console.log("Zadres dn: " + this.odDnia + " " + this.doDnia);

    this.parametryService.filtrPszok.subscribe((pszok) => {
      this.currentKomorkaOrganizacyjnaSymbol = pszok;
    });
    console.log("KO" + this.currentKomorkaOrganizacyjnaSymbol);

    const hasRecordId: boolean = this.route.snapshot.paramMap.has("id");
    if (hasRecordId) {
      this.rocordId = +this.route.snapshot.paramMap.get("id");
    } else {
      this.rocordId = null;
    }

    this.przyjecieFormGroup = this.formBuilder.group({
      szukaj: this.formBuilder.group({
        szukajAdresu: new UntypedFormControl(""),
        szukajMiejscowosc: new UntypedFormControl(""),
        szukajUlica: new UntypedFormControl(""),
        szukajNrDomu: new UntypedFormControl(""),
      }),
      mieszkaniec: this.formBuilder.group({
        gmina: new UntypedFormControl("", [Validators.required]),
        miejscowosc: new UntypedFormControl("", [
          Validators.required,
          Validators.minLength(3),
        ]),

        ulica: new UntypedFormControl("", [
          Validators.required,
          Validators.minLength(1),
        ]),

        nrDomu: new UntypedFormControl("", [
          Validators.required,
          Validators.minLength(1),
        ]),
        wlasciciel: new UntypedFormControl({ value: "", disabled: true }),
        czyFirma: new UntypedFormControl({ value: "", disabled: true }),
      }),
      selekcja: this.formBuilder.group({
        data: new UntypedFormControl(this.currentFiltrDzien, [
          Validators.pattern("^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]$"),
        ]),
        rodzajSelekcji: new UntypedFormControl(""),
        brutto: new UntypedFormControl(null, [Validators.pattern("^[0-9]*$")]),
        tara: new UntypedFormControl(null, [Validators.pattern("^[0-9]*$")]),
        netto: new UntypedFormControl(null, [Validators.pattern("^[0-9]*$")]),
        liczbaSztuk: new UntypedFormControl(null, [
          Validators.pattern("^[0-9]*$"),
        ]),
      }),
      notatka: this.formBuilder.group({
        uwagi: new UntypedFormControl(""),
        deklaracja: new UntypedFormControl({ value: "", disabled: true }),
        id: new UntypedFormControl({ value: "", disabled: true }),
      }),
    });

    this.searchSubject
      .pipe(
        switchMap(({ miejscowosc, ulica, nr }) => {
          return this.deklaracjeService
            .szukajMiejscowoscUlicaNr(miejscowosc, ulica, nr)
            .pipe(
              catchError((error) => {
                console.error("Błąd podczas zapytania:", error);
                this.listaAdresow = [];
                return of([]);
              })
            );
        })
      )
      .subscribe((result) => {
        console.log("Otrzymano wynik:", result);
        this.listaAdresow = result;
      });

    this.gminaService.getObslugiwaneGminy().subscribe((data) => {
      this.gminy = data;
    });

    this.rodzajSelekcjiService.getRodzajeSelekcji().subscribe((data) => {
      this.rodzajeSelekcji = data;
    });

    if (this.rocordId != null) this.pobierzRekord(this.rocordId);

    this.updateButtons();

    this.subscription = this.wazeniaService.resetForm$.subscribe(() =>
      this.clearForm()
    )

  }

  ngOnDestroy() {
    this.searchSubject.unsubscribe();
  }

  private pobierzRekord(id: number) {
    this.wazeniaService.getWazenieById(id).subscribe((data) => {
      this.record = data;

      if (this.record != null) {
        this.przyjecieFormGroup
          .get("mieszkaniec.gmina")
          .setValue(this.record.gmina.nazwaGminy);
        this.przyjecieFormGroup
          .get("mieszkaniec.miejscowosc")
          .setValue(this.record.miejscowosc);
        this.przyjecieFormGroup
          .get("mieszkaniec.ulica")
          .setValue(this.record.ulica);
        this.przyjecieFormGroup
          .get("mieszkaniec.nrDomu")
          .setValue(this.record.nrDomu);
        this.przyjecieFormGroup
          .get("mieszkaniec.wlasciciel")
          .setValue(this.record.wlasciciel);

        if (this.record.rodzajSelekcji == null)
          this.przyjecieFormGroup.get("selekcja.rodzajSelekcji").setValue("");
        else
          this.przyjecieFormGroup
            .get("selekcja.rodzajSelekcji")
            .setValue(this.record.rodzajSelekcji.nazwa);

        this.przyjecieFormGroup
          .get("selekcja.brutto")
          .setValue(this.record.brutto);
        this.przyjecieFormGroup.get("selekcja.tara").setValue(this.record.tara);
        this.przyjecieFormGroup
          .get("selekcja.netto")
          .setValue(this.record.waga);
        this.przyjecieFormGroup
          .get("selekcja.liczbaSztuk")
          .setValue(this.record.liczbaSztuk);

        this.przyjecieFormGroup
          .get("notatka.uwagi")
          .setValue(this.record.uwagi);
        this.przyjecieFormGroup
          .get("notatka.deklaracja")
          .setValue(this.record.deklaracja);
        this.przyjecieFormGroup.get("notatka.id").setValue(this.record.id);
      } else {
        this.rocordId = null;
      }
    });
  }

  clearForm() {
    this.przyjecieFormGroup.get("szukaj.szukajAdresu").setValue("");
    this.przyjecieFormGroup.get("szukaj.szukajMiejscowosc").setValue("");
    this.przyjecieFormGroup.get("szukaj.szukajUlica").setValue("");
    this.przyjecieFormGroup.get("szukaj.szukajNrDomu").setValue("");
    this.przyjecieFormGroup.get("mieszkaniec.wlasciciel").setValue("");
    this.przyjecieFormGroup.get("mieszkaniec.gmina").setValue("");
    this.przyjecieFormGroup.get("mieszkaniec.miejscowosc").setValue("");
    this.przyjecieFormGroup.get("mieszkaniec.ulica").setValue("");
    this.przyjecieFormGroup.get("mieszkaniec.nrDomu").setValue("");
    this.przyjecieFormGroup.get("mieszkaniec.wlasciciel").setValue("");
    this.przyjecieFormGroup.get("selekcja.rodzajSelekcji").setValue("");
    this.przyjecieFormGroup.get("selekcja.brutto").setValue(null);
    this.przyjecieFormGroup.get("selekcja.tara").setValue(null);
    this.przyjecieFormGroup.get("selekcja.netto").setValue(null);
    this.przyjecieFormGroup.get("selekcja.liczbaSztuk").setValue(null);
    this.przyjecieFormGroup.get("notatka.uwagi").setValue("");
    this.przyjecieFormGroup.get("notatka.deklaracja").setValue("");
    this.przyjecieFormGroup.get("notatka.id").setValue("");
    this.updateButtons();
  }

  get szukajAdresu() {
    return this.przyjecieFormGroup.get("szukaj.szukajAdresu");
  }
  get szukajMiejscowosc() {
    return this.przyjecieFormGroup.get("szukaj.szukajMiejscowosc");
  }
  get szukajUlica() {
    return this.przyjecieFormGroup.get("szukaj.szukajUlica");
  }
  get szukajNrDomu() {
    return this.przyjecieFormGroup.get("szukaj.szukajNrDomu");
  }

  get gmina() {
    return this.przyjecieFormGroup.get("mieszkaniec.gmina");
  }
  get miejscowosc() {
    return this.przyjecieFormGroup.get("mieszkaniec.miejscowosc");
  }
  get ulica() {
    return this.przyjecieFormGroup.get("mieszkaniec.ulica");
  }
  get nrDomu() {
    return this.przyjecieFormGroup.get("mieszkaniec.nrDomu");
  }
  get wlasciciel() {
    return this.przyjecieFormGroup.get("mieszkaniec.wlasciciel");
  }
  get czyFirma() {
    return this.przyjecieFormGroup.get("mieszkaniec.czyFirma");
  }

  get rodzajSelekcji() {
    return this.przyjecieFormGroup.get("selekcja.rodzajSelekcji");
  }
  get data() {
    return this.przyjecieFormGroup.get("selekcja.data");
  }
  get brutto() {
    return this.przyjecieFormGroup.get("selekcja.brutto");
  }
  get tara() {
    return this.przyjecieFormGroup.get("selekcja.tara");
  }
  get netto() {
    return this.przyjecieFormGroup.get("selekcja.netto");
  }
  get liczbaSztuk() {
    return this.przyjecieFormGroup.get("selekcja.liczbaSztuk");
  }

  get uwagi() {
    return this.przyjecieFormGroup.get("notatka.uwagi");
  }
  get deklaracja() {
    return this.przyjecieFormGroup.get("notatka.deklaracja");
  }

  nettoIsValid() {
    return !(
      isNaN(this.przyjecieFormGroup.get("selekcja.netto").value) ||
      this.przyjecieFormGroup.get("selekcja.netto").value == null
    );
  }
  bruttoIsValid() {
    return !(
      isNaN(this.przyjecieFormGroup.get("selekcja.brutto").value) ||
      this.przyjecieFormGroup.get("selekcja.brutto").value == null
    );
  }
  rodzajSelekcjiIsValid() {
    if (this.przyjecieFormGroup.get("selekcja.rodzajSelekcji").value === "") {
      return false;
    } else {
      return true;
    }
  }

  //Czy można zakończyć obsługę klienta
  disableSaveAndClose() {
    if (this.rodzajSelekcjiIsValid && this.nettoIsValid) {
      return false;
    } else {
      return true;
    }
  }

  //Czy zapisać klienta do bufora - musimy znać tylko brutto
  disableSaveAndContinue() {
    if (this.bruttoIsValid) {
      return false;
    } else {
      return true;
    }
  }

  onChangeSelekcja() {
    this.updateFormFields();
  }

  onChangeMieszkaniec() {
    this.updateFormFields();
    this.resetSzukajInfo();
    this.handleSumujWazenia();
  }

  private updateFormFields() {
    this.obliczNetto();
    this.updateButtons();
  }

  private resetSzukajInfo() {
    this.przyjecieFormGroup.get("mieszkaniec.wlasciciel").setValue("");
    this.przyjecieFormGroup.get("mieszkaniec.czyFirma").setValue("");
    this.przyjecieFormGroup.get("notatka.deklaracja").setValue("");
  }

  onChangeBrutto() {
    this.obliczNetto();
    this.updateButtons();
  }

  onChangeTara() {
    this.obliczNetto();
    this.updateButtons();
  }

  onChangeNetto() {
    this.obliczNetto();
    this.updateButtons();
  }

  isValidNetto() {
    if (
      this.przyjecieFormGroup.get("selekcja.netto").value == "" ||
      isNaN(this.przyjecieFormGroup.get("selekcja.netto").value) ||
      this.przyjecieFormGroup.get("selekcja.netto").value == null
    )
      return false;
    else return true;
  }

  isValidBrutto() {
    if (
      this.przyjecieFormGroup.get("selekcja.brutto").value == "" ||
      isNaN(this.przyjecieFormGroup.get("selekcja.brutto").value) ||
      this.przyjecieFormGroup.get("selekcja.brutto").value == null
    )
      return false;
    else return true;
  }

  isValidTara() {
    if (
      this.przyjecieFormGroup.get("selekcja.tara").value == "" ||
      isNaN(this.przyjecieFormGroup.get("selekcja.tara").value) ||
      this.przyjecieFormGroup.get("selekcja.tara").value == null
    )
      return false;
    else return true;
  }

  isValidMieszkaniec() {
    if (this.przyjecieFormGroup.get("mieszkaniec.gmina").value == "")
      return false;
    if (this.przyjecieFormGroup.get("mieszkaniec.miejscowosc").value == "")
      return false;
    if (this.przyjecieFormGroup.get("mieszkaniec.nrDomu").value == "")
      return false;

    return true;
  }

  isValidSelekcja() {
    if (this.przyjecieFormGroup.get("selekcja.rodzajSelekcji").value == "")
      return false;

    return true;
  }

  onChangeRodzajSelekcji() {
    this.updateButtons();
  }

  onUpdateNetto() {
    // this.przyjecieFormGroup.get('selekcja.brutto').setValue(null);
    // this.przyjecieFormGroup.get('selekcja.tara').setValue(null);
  }

  obliczNetto() {
    const brutto = ConvertStringToNumber(
      this.przyjecieFormGroup.get("selekcja.brutto").value
    );
    const tara = ConvertStringToNumber(
      this.przyjecieFormGroup.get("selekcja.tara").value
    );
    const netto = ConvertStringToNumber(
      this.przyjecieFormGroup.get("selekcja.netto").value
    );

    
    if (isNaN(tara) || isNaN(brutto) || tara == null || brutto == null) {
      //Nie da się obliczyć
      this.przyjecieFormGroup.get("selekcja.netto").setValue(null);
    } else {
      //Obliczamy brutto-tara
      const result: number | string = +brutto - +tara;
      this.przyjecieFormGroup.get("selekcja.netto").setValue(result);
    }
    
    // else{
    //   //Mamy netto - nie ma uzupełnione brutto
    //   if(isNaN(brutto)){
    //     this.przyjecieFormGroup.get('selekcja.brutto').setValue(netto);
    //     this.przyjecieFormGroup.get('selekcja.tara').setValue('0');
    //     brutto=netto;
    //     tara=0;
    //   }

    //   if(isNaN(tara)){
    //     this.przyjecieFormGroup.get('selekcja.brutto').setValue(netto);
    //     this.przyjecieFormGroup.get('selekcja.tara').setValue('0');
    //   }
    // }

    this.updateButtons();

    console.log(`obliczNetto() ${brutto} - ${tara}`);
  }

  isBtn1LabelVisible: boolean = true;
  isBtn2LabelVisible: boolean = false;

  updateButtons() {
    console.log("updateButtons()");
    this.btn1Label = "??";

    //Przycisk 1
    if (
      this.isValidNetto() &&
      this.isValidSelekcja() &&
      this.isValidMieszkaniec()
    ) {
      this.btn1Label = "Zapisz";
      this.isBtn1LabelVisible = true;
    } else if (this.isValidBrutto() && this.isValidMieszkaniec()) {
      this.btn1Label = "Do kolejki";
      this.isBtn1LabelVisible = true;
    } else {
      this.btn1Label = "Zapisz";
      this.isBtn1LabelVisible = false;
    }

    console.log(this.isValidNetto());
    console.log(this.isValidBrutto());
    console.log("AAAAAAAAA");
    //Przycisk 2
    if (
      this.isValidNetto() &&
      this.isValidSelekcja() &&
      this.isValidMieszkaniec()
    ) {
      this.btn2Label = "Zapisz i do kolejnego ważenia";
      this.isBtn2LabelVisible = true;
    } else {
      this.btn2Label = "Zapisz";
      this.isBtn2LabelVisible = false;
    }
  }

  onSubmit(event) {
    console.log(event.submitter.name);
    this.messageService.clear();

    if (this.przyjecieFormGroup.invalid) {
      this.przyjecieFormGroup.markAllAsTouched();
      return;
    }

    // eslint-disable-next-line prefer-const
    let przyjecie = new Przyjecie();
    przyjecie.komorkaOrganizacyjna = this.currentKomorkaOrganizacyjnaSymbol;
    przyjecie.gmina = this.przyjecieFormGroup.get("mieszkaniec.gmina").value;
    przyjecie.miejscowosc = this.przyjecieFormGroup.get(
      "mieszkaniec.miejscowosc"
    ).value;
    przyjecie.ulica = this.przyjecieFormGroup.get("mieszkaniec.ulica").value;
    przyjecie.nrDomu = this.przyjecieFormGroup.get("mieszkaniec.nrDomu").value;
    przyjecie.zamieszkale = true;
    if (this.przyjecieFormGroup.get("mieszkaniec.czyFirma").value === true)
      przyjecie.wlasciciel = this.przyjecieFormGroup.get(
        "mieszkaniec.wlasciciel"
      ).value;

    przyjecie.data = this.przyjecieFormGroup.get("selekcja.data").value;
    przyjecie.rodzajSelekcji = this.przyjecieFormGroup.get(
      "selekcja.rodzajSelekcji"
    ).value;
    przyjecie.brutto = this.przyjecieFormGroup.get("selekcja.brutto").value;
    przyjecie.tara = this.przyjecieFormGroup.get("selekcja.tara").value;
    przyjecie.waga = this.przyjecieFormGroup.get("selekcja.netto").value;
    przyjecie.liczbaSztuk = this.przyjecieFormGroup.get(
      "selekcja.liczbaSztuk"
    ).value;

    przyjecie.uwagi = this.przyjecieFormGroup.get("notatka.uwagi").value;
    przyjecie.deklaracja =
      this.przyjecieFormGroup.get("notatka.deklaracja").value;

    if (event.submitter.name == "save_and_close") {
      console.log("save_and_close");
      przyjecie.zapiszKontynuuj = false;
    } else if (event.submitter.name == "save_and_continue") {
      console.log("save_and_continue");
      przyjecie.zapiszKontynuuj = true;
    }

    // console.log(this.przyjecieFormGroup.get('notatka.id').value);
    if (this.przyjecieFormGroup.get("notatka.id").value === "") {
      console.log("Brak ID");
      przyjecie.id = null;
    } else {
      przyjecie.id = this.przyjecieFormGroup.get("notatka.id").value;
      console.log("Id=" + przyjecie.id);
    }

    //  console.log(JSON.stringify(przyjecie));

    if (przyjecie.id == null) {
      console.log("Nowy rekord");
      console.log(JSON.stringify(przyjecie));
      this.wazeniaService.dodajWazenie(przyjecie).subscribe({
        next: (response) => {
          if (response.id == null) {
            this.messageService.add({
              severity: "error",
              summary: "Zapis do bazy danych",
              detail: "Błędy walidacji: " + response.errorMessage,
            });
          } else {
            this.przyjecieFormGroup
              .get("notatka.id")
              .setValue(`${response.id}`);
            this.rocordId = response.id;
            this.pobierzRekord(this.rocordId);
            this.messageService.add({
              severity: "success",
              summary: "Zapis do bazy danych",
              detail: "Zapisano poprawnie pod pozycją " + response.id,
            });
            this.handleSumujWazenia();
          }
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Zapis do bazy danych",
            detail: "Błąd:" + err.message,
          });
          alert(`There was an error: ${err.message}`);
        },
      });
    } else {
      console.log("Edytuj rekord");
      console.log(JSON.stringify(przyjecie));
      this.wazeniaService.edytujWazenie(przyjecie).subscribe({
        next: (response) => {
          this.rocordId = response.id;
          this.pobierzRekord(this.rocordId);
          this.messageService.add({
            severity: "success",
            summary: "Zapis do bazy danych",
            detail: "Zmiany zostały zapisane - pozycja " + response.id,
          });
          this.handleSumujWazenia();
        },
        error: (err) => {
          this.messageService.add({
            severity: "error",
            summary: "Zapis do bazy danych",
            detail: "Błąd:" + err.message,
          });
          alert(`There was an error: ${err.message}`);
        },
      });
      
    }

    //   if( event.submitter.name == "save_and_close" ){
    //       const url=`wazenia-zakonczone`;
    //       console.log('save_and_close: ' + url);
    //       // this.router.navigate([url]);

    //       // this.router.navigate([url]).then(() => {
    //       //   window.location.reload();
    //       // });

    //     }
    //   else if( event.submitter.name == "save_and_continue"){
    //     const url=`wazenia-rozpoczete`;
    //     console.log('save_and_continue: ' + url);
    //     // this.router.navigate([url]);

    //     // this.router.navigate([url]).then(() => {
    //     //   window.location.reload();
    //     // });

    //   }
    //   else if( event.submitter.name == "reset")
    //     { console.log('reset'); }
  }

  redirectTo(uri: string) {
    // this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    // this.router.navigate([uri]));

    this.router.navigate([uri]).then(() =>
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    );
  }

  // handleSearchAdres() {
  //   // const szukaj = this.przyjecieFormGroup.get('szukaj.szukajAdresu').value;

  //   const miejscowosc = this.przyjecieFormGroup.get('szukaj.szukajMiejscowosc').value;
  //   const ulica = this.przyjecieFormGroup.get('szukaj.szukajUlica').value;
  //   const nr = this.przyjecieFormGroup.get('szukaj.szukajNrDomu').value;

  //   if(miejscowosc.length>0 || ulica.length>0 || nr.length>0){
  //     console.log("handleSearchAdres()" + miejscowosc + " " + ulica + " " + nr);
  //     this.deklaracjeService.szukajMiejscowoscUlicaNr(miejscowosc, ulica, nr).subscribe(this.processResult());
  //     // console.log(this.listaAdresow);
  //   }else{
  //     this.listaAdresow = [];
  //   }

  // }

  handleSearchAdres(): void {
    const miejscowosc =
      this.przyjecieFormGroup.get("szukaj.szukajMiejscowosc").value || "";
    const ulica = this.przyjecieFormGroup.get("szukaj.szukajUlica").value || "";
    const nr = this.przyjecieFormGroup.get("szukaj.szukajNrDomu").value || "";

    // Przesyłanie tylko danych jeśli istnieje jakaś zmiana
    if (miejscowosc.length > 0 || ulica.length > 0 || nr.length > 0) {
      this.searchSubject.next({ miejscowosc, ulica, nr });
    }
  }

  handleSumujWazenia() {
    // const szukaj = this.przyjecieFormGroup.get('szukaj.szukajAdresu').value;

    console.log("handleSearchAdres()");

    const gmina = encodeURIComponent(
      this.przyjecieFormGroup.get("mieszkaniec.gmina").value
    );
    const miejscowosc = encodeURIComponent(
      this.przyjecieFormGroup.get("mieszkaniec.miejscowosc").value
    );
    const ulica = encodeURIComponent(
      this.przyjecieFormGroup.get("mieszkaniec.ulica").value
    );
    const nrDomu = encodeURIComponent(
      this.przyjecieFormGroup.get("mieszkaniec.nrDomu").value
    );
    const rodzajSelekcji = encodeURIComponent(
      this.przyjecieFormGroup.get("selekcja.rodzajSelekcji").value
    );

    if (
      gmina.length > 0 &&
      miejscowosc.length > 0 &&
      ulica.length > 0 &&
      nrDomu.length > 0
    ) {
      console.log(
        "handleSearchAdres()" +
          gmina +
          " " +
          miejscowosc +
          " " +
          ulica +
          " " +
          nrDomu
      );
      this.wazeniaService
        .getSumaWazenList(
          gmina,
          miejscowosc,
          ulica,
          nrDomu,
          this.odDnia,
          this.doDnia
        )
        .subscribe(this.processResultSumaWazen());
      // console.log(this.listaAdresow);
    } else {
      this.listaAdresow = [];
    }
  }

  selectAdres(event: Event) {
    console.log(JSON.stringify(event));
  }

  // Uzupełnienie adresu na podstawie deklaracji
  completeAdres(adres: Deklaracja) {
    this.przyjecieFormGroup
      .get("mieszkaniec.miejscowosc")
      .setValue(adres.miejscowosc);
    this.przyjecieFormGroup.get("mieszkaniec.ulica").setValue(adres.ulica);
    this.przyjecieFormGroup.get("mieszkaniec.nrDomu").setValue(adres.nrDomu);
    this.przyjecieFormGroup
      .get("mieszkaniec.wlasciciel")
      .setValue(adres.wlasciciel);
    this.przyjecieFormGroup
      .get("mieszkaniec.czyFirma")
      .setValue(adres.czyFirma);
    this.przyjecieFormGroup.get("notatka.deklaracja").setValue(adres.uwagi);

    console.log(adres.gmina);
    switch (adres.gmina) {
      case "Miasto Zamość": {
        this.przyjecieFormGroup
          .get("mieszkaniec.gmina")
          .setValue("miasto Zamość");
        break;
      }
      case "Gmina Zamość": {
        this.przyjecieFormGroup.get("mieszkaniec.gmina").setValue("Zamość");
        break;
      }
      case "Gmina Skierbieszów": {
        this.przyjecieFormGroup
          .get("mieszkaniec.gmina")
          .setValue("Skierbieszów");
        break;
      }
      case "Gmina Łabunie": {
        this.przyjecieFormGroup.get("mieszkaniec.gmina").setValue("Łabunie");
        break;
      }
      default: {
        this.przyjecieFormGroup.get("mieszkaniec.gmina").setValue("");
      }
    }
    console.log("completeAdres -> Sumowanie ");
    this.handleSumujWazenia();
    console.log(JSON.stringify(this.listaSumaWazen));

    this.nrDomuField.nativeElement.focus();
  }

  processResult() {
    return (data) => {
      this.listaAdresow = data;
    };
  }

  processResultSumaWazen() {
    return (data) => {
      console.log(data);
      this.listaSumaWazen = data;
    };
  }
}

function ConvertStringToNumber(input: string) {
  if (!input) return NaN;

  if (input.toString().trim().length == 0) {
    return NaN;
  }
  return Number(input);
}
